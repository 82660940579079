import React, { Component } from "react";
import { getDynamicDate } from "../method/dynamic";
import HomeFooter from "../widget/home_footer";
import { Header } from "../widget/home_top";

export default class DynamicPage extends Component {
  constructor() {
    super();
    this.state = {
      dynamic_links: [],
      dynamic_link: "",
      locations_h: [],
      locations_b: [],
      locations_c: [],
      locations_r: [],
      location: null,
    };
  }

  componentDidMount() {
    getDynamicDate(this);
  }

  render() {
    const { dynamic_link } = this.state;
    return (
      <React.StrictMode>
        <Header props={this} />
        <frameset cols="100%">
          <frame src={dynamic_link} />
        </frameset>
        <HomeFooter props={this} />
      </React.StrictMode>
    );
  }
}
