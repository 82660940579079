import React from 'react';
import areena_logo from '../asset/areena_logo.png';
import nexa_logo from '../asset/nexa_logo.png';
import commercial_logo from '../asset/commercial_logo.png';
import brandBgArena from '../asset/1.jpg';
import brandBgNexa from '../asset/brandbg_nexa.jpg';
import brandBgCommercial from '../asset/brandbg_commercial.jpg';
import tvl from '../asset/tvl.png';
import truevalue from '../asset/tv.jpg';
import '../style/hm2.css';

function HomeBrands() {
	const brands = [
		{
			bg: brandBgArena,
			logo: areena_logo,
			title: 'KALYANI ARENA',
			subtitle:
				"Explore the 'World of Arena' built on experience, technology & design.",
			color: 'white',
			hvcolor: '#ddffffff',
			link: 'arena',
			// hvcolor: "rgba(255 , 255, 355, 0.8)",
		},
		{
			bg: brandBgNexa,
			logo: nexa_logo,
			title: 'KALYANI NEXA',
			subtitle:
				"Indulge in the premium experience of innovation & extraordinary creation that's created to inspire.",
			color: 'white',
			hvcolor: 'black',
			link: 'nexa',
			// hvcolor: "rgba(255 , 255, 355, 0.5)",
		},
		{
			bg: brandBgCommercial,
			logo: commercial_logo,
			title: 'KALYANI COMMERCIAL',
			subtitle: 'Drive the strong partner for your success.',
			color: 'white',
			hvcolor: '#EE1C25',
			link: 'commercial',
			// hvcolor: "rgba(100 , 100, 100, 0.8)",
		},
		{
			bg: truevalue,
			logo: tvl,
			title: 'KALYANI TRUEVALUE',
			subtitle: 'Leading destination for pre-owned cars.',
			color: 'white',
			hvcolor: '#EE1C25',
			link: 'http://truevalue.kalyanimotors.com/',
			// hvcolor: "rgba(100 , 100, 100, 0.8)",
		},
	];
	// return window.innerWidth > 600 ? null : (
	return (
		<div className="hm2_a">
			{brands.map((br, k) => (
				<div
					// title={br.title}
					key={k}
					className="hm2_b"
					style={{ backgroundImage: `url(${br.bg})` }}
					onClick={() => {
						window.location = '/allcars/0/' + br.link;
						if (br.title === 'KALYANI TRUEVALUE') {
							window.location = br.link;
						}
						// if (k === 0) window.location.assign(br.link);
						// else window.open(br.link, "_blank");
					}}
				>
					<div className="hm2_c">
						<div className="hm2_d">
							<img alt="img" className="hm2_e" src={br.logo} />
						</div>
						<div className="hm2_f" style={{ color: br.color }}>
							{br.title}
						</div>
						<div className="subtitle">{br.subtitle}</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default HomeBrands;
