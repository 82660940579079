import CarDetails from '../widget/car_details';
import CarSpecification from '../widget/car_specif';
import CarSummary from '../widget/car_summary';
import HomeFooter from '../widget/home_footer';

import React, { Component } from 'react';
import { getCar } from '../method/car';
import { ErrorPage, LoadingPage } from '../widget/warning_page';
import { CarPopup, EnquiryPopup } from '../widget/popups';
import { Header } from '../widget/home_top';
import { MetaTags } from 'react-meta-tags';
import CarPrice from '../widget/car_price';
import CarPhotoDesc from '../widget/CarPhotoDesc';
import ChooseVersion from '../widget/ChooseVersion';

export default class Car extends Component {
	constructor() {
		super();
		this.state = {
			car: {},
			loading: true,
			error: null,
			imgCount: 0,
			colorCount: 0,
			versionCount: 0,
			homeSearchs: [],
			locations_h: [],
			locations_b: [],
			locations_c: [],
			locations_r: [],
			location: {},
			enquiryPopup: null,
			selectedImage: null,
			emiCalc: false,
			intrest: 8,
			month: 60,
			dynamic_links: [],
		};
	}

	componentDidMount() {
		getCar(this);
	}

	render() {
		const { loading, error, car } = this.state;
		if (loading) return <LoadingPage />;
		if (error !== null) return <ErrorPage />;
		return (
			<React.StrictMode>
				<MetaTags>
					<meta charset="UTF-8" />
					<meta name="description" content={car.seo_desc} />
					<meta name="keywords" content={car.seo_tag} />
					<meta name="title" content={car.seo_title} />
				</MetaTags>
				<Header props={this} />
				<div className="cr_1c">
					<div className="cr_1d">
						<CarDetails props={this} />
						<CarPhotoDesc props={this} />
						<CarPrice props={this} />
						<CarSpecification props={this} />
						<CarSummary props={this} />
						<ChooseVersion props={this} />
					</div>
				</div>
				<HomeFooter props={this} />
				<EnquiryPopup props={this} />
				<CarPopup props={this} />
			</React.StrictMode>
		);
	}
}
