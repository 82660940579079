//  Go mechanic clone Design for kalyani moters
//  Design and json integration
//  MUHAMMED JASEEL
//  30 aug 2021

import { getImage } from '../module/api_init';
import { makespaceline } from '../module/simple';

import '../style/summer-section.css';

function HomeMunsoon({ props }) {
    console.log(props.state.sesonService.season_title);
    const { sesonService, location } = props.state;

    return (
        // <div className="hb_1a">
        //   <div className="hb_1d" />
        //   <div className="hb_1b">
        //     <div className="hb_1b_a">{sesonService.season_title}</div>
        //   </div>
        //   <div className="hb_1c">{sesonService.description}</div>
        //   <div className="row">
        //     <div className="home_monsoon_area">
        //       {sesonService.services.map((s, k) => (
        //         <div key={k}>
        //           <div
        //             className="home_monsoon_container"
        //             onClick={() =>
        //               (window.location = `/service/${makespaceline(s.title)}/${
        //                 location.id
        //               }/seasonservice`)
        //             }
        //           >
        //             <img
        //               className="home_monsoon_img"
        //               src={getImage(s.image)}
        //               alt="img"
        //             />
        //           </div>
        //           <div className="home_monsoon_content_title">{s.title}</div>
        //         </div>
        //       ))}
        //     </div>
        //   </div>
        // </div>

        <>
            <div className="hr-line"></div>
            <div className="main-bg">
                <div className="left-cont">
                    <div className="heading-title">
                        {sesonService.season_title}
                    </div>
                    <div className="heading-description">
                        {sesonService.description}
                    </div>
                </div>

                <div className="right-cont">
                    {sesonService.services.map((service, index) => {
                        return (
                            <div className="img-cont" key={index}>
                                <div
                                    className="img-div"
                                    onClick={() => {
                                        window.location = `/service/${makespaceline(
                                            service.title
                                        )}/${location.id}/seasonservice`;
                                    }}
                                >
                                    <img
                                        src={getImage(service.image)}
                                        className="img"
                                        alt="img"
                                    />
                                </div>
                                <div className="img-content_title">
                                    <h4>{service.title}</h4>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default HomeMunsoon;
