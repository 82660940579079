import React, { useEffect } from 'react';
import { getFile, getImage } from '../module/api_init';
import { getComaPrice } from '../module/simple';

function CarDetails({ props }) {
	const { car, colorCount, imgCount, emiCalc } = props.state;
	var { versionCount } = 0;
	const { intrest, month } = props.state;
	console.log('jhbjbb', props.state.versionCount);
	// const versionCount = window.location.pathname.split('/')[3];

	if (props.state.versionCount === 0) {
		versionCount = window.location.pathname.split('/')[3];
	} else {
		versionCount = props.state.versionCount;
	}

	const executeScroll = (id) => {
		var element = document.getElementById(id);
		if (element === null) return;
		var elementPosition = element.getBoundingClientRect().top;
		window.scrollTo({
			top: window.scrollY + elementPosition - 70,
			behavior: 'smooth',
		});
	};
	const star = (
		<svg
			className="cr_1f_a"
			fill="currentcolor"
			role="img"
			aria-label="rating icon"
			viewBox="0 0 11.62 11.06"
		>
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M11.55 4.44l-2.7 2.63.64 3.72a.26.26 0 0 1-.1.23.21.21 0 0 1-.24 0L5.81 9.28 2.47 11a.22.22 0 0 1-.24 0 .24.24 0 0 1-.1-.23l.64-3.72-2.7-2.61A.23.23 0 0 1 0 4.2.26.26 0 0 1 .2 4l3.73-.5L5.6.12a.24.24 0 0 1 .4 0L7.69 3.5l3.73.5a.26.26 0 0 1 .19.16.26.26 0 0 1-.06.28z"
			></path>
		</svg>
	);

	const price = car.varients[versionCount].products[colorCount].price;
	const emi = (
		(((price * intrest) / 100) * (month / 12) + price) /
		month
	).toString();

	const imgs = car.varients[versionCount].products[colorCount].images;
	const proname = car.varients[versionCount].products[colorCount].name;
	return (
		<React.StrictMode>
			<div className="cr_1e" id="cr_1">
				{car.varients[versionCount].description}
			</div>
			<div className="cr_1f">
				{star}
				{star}
				{star}
				{star}
				{star}
			</div>
			<div className="cr_1g">
				<div className="cr_1g_a" onClick={() => executeScroll('cr_1')}>
					OVERVIEW
				</div>
				<div className="cr_1g_a" onClick={() => executeScroll('cr_2')}>
					IMAGES
				</div>
				<div className="cr_1g_a" onClick={() => executeScroll('cr_3')}>
					COLORS
				</div>
				<div className="cr_1g_a" onClick={() => executeScroll('cr_4')}>
					SPECIFICATIONS
				</div>
				<div className="cr_1g_a" onClick={() => executeScroll('cr_5')}>
					ABOUT
				</div>
				<div className="cr_1g_a" onClick={() => executeScroll('cr_6')}>
					DESCRIPTION
				</div>
			</div>
			{/* ////////////car//////////// */}
			<div className="cr_1h">
				<div className="cr_1i" id="cr_2">
					<img
						className="cr_1j"
						src={getImage(imgs[imgCount].images)}
						alt="Car"
						onClick={() => props.setState({ selectedImage: imgCount })}
					/>
					<div className="cr_1_a_a">
						{imgs.map((i, k) => (
							<img
								key={k}
								alt="Car"
								onClick={() => props.setState({ imgCount: k })}
								style={imgCount === k ? { borderColor: 'gray' } : {}}
								src={getImage(i['images'])}
								className="cr_1k"
							/>
						))}
					</div>
				</div>
				<div className="cr_1m">
					<div className="row">
						<div className="cr_1n">
							<div className="cr_1s">
								<div className="cr_1o">
									<div className="cr_1p">Version</div>
									<div className="cr_1q">
										<select
											className="cr_1q_a"
											onChange={(e) =>
												props.setState({
													versionCount: e.target.value,
													colorCount: 0,
													imgCount: 0,
												})
											}
										>
											{car.varients.map((v, k) => (
												<option key={k} value={k}>
													{console.log(v)}
													{v.var_title}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="cr_1n">
							<div className="cr_1s">
								<div className="cr_1t">
									<div className="cr_1p">Color</div>
									<div className="cr_1q">
										{car.varients[versionCount].products[colorCount].color_name}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cr_1x">₹{getComaPrice(price)} /-</div>
					<div className="cr_1y">Ex-showroom Price</div>
					<div
						className="cr_1y_a"
						onClick={() => window.open(getFile(car.file), '_blank')}
					>
						Download Brochure
					</div>
					<div className="cr_1z">
						<div className="cr_1u">
							<div>
								<div className="cr_1aa">Calculate Your EMI</div>
								<div
									className="cr_1ab"
									onClick={() => props.setState({ emiCalc: !emiCalc })}
								>
									{!emiCalc ? (
										'EMI Calculator'
									) : (
										<>EMI₹ {getComaPrice(emi.split('.')[0])} / month</>
									)}
								</div>
							</div>
							<div
								className="cr_1ac"
								onClick={() =>
									props.setState({
										enquiryPopup: { car: proname, type: 2 },
									})
								}
							>
								Get Emi Offer
							</div>
						</div>
					</div>
					{emiCalc ? (
						<div className="cr_1_a_b">
							<form className="row">
								Intrest (%)
								<input
									onChange={(e) => {
										if (e.target.value > 0 && e.target.value < 101)
											props.setState({
												intrest: e.target.value,
											});
									}}
									min="8"
									max="100"
									type="number"
									className="cr_1_a_c"
									step="0.25"
									defaultValue="8"
								/>
								<div style={{ width: '20%' }} />
								Time (Month)
								<select
									onChange={(e) => {
										if (e.target.value > 0)
											props.setState({
												month: e.target.value,
											});
									}}
									className="cr_1_a_c"
								>
									<option value="60">60 Months</option>
									<option value="48">48 months</option>
									<option value="36">36 months</option>
									<option value="24">24 months</option>
									<option value="12">12 months</option>
								</select>
							</form>
						</div>
					) : null}
					<div
						className="cr_1ad"
						onClick={() =>
							props.setState({
								enquiryPopup: { car: proname, type: 0 },
							})
						}
					>
						Get on-road Price
					</div>
					<div
						className="cr_1ae"
						onClick={() =>
							props.setState({
								enquiryPopup: { car: proname, type: 3 },
							})
						}
					>
						Get Best Offers
					</div>
				</div>
			</div>
			{/* ////////////////////////////////////////// */}
			<div className="cr_1af" id="cr_3">
				{car.varients[versionCount].products.map((i, k) => (
					<div className="row" key={k}>
						<div
							className="cr_1ag"
							onClick={() => props.setState({ colorCount: k, imgCount: 0 })}
							style={{
								borderRadius: '5px',
								boxShadow: '.3rem .5rem  0.3125rem -0.25rem rgb(0 0 0 / 22%)',
								marginTop: '1rem',
								background: i.colour_code,
								borderColor: colorCount === k ? 'gray' : null,
							}}
						/>
						<div className="cr_1ah" style={{ marginTop: '1rem' }}>
							{i.color_name}
						</div>
					</div>
				))}
			</div>
		</React.StrictMode>
	);
}

export default CarDetails;
