import axios from 'axios';
import { makelinespace } from '../module/simple';

export async function getCar(props) {
	var path = window.location.pathname.split('/')[2];
	var loc_id = window.location.pathname.split('/')[3];
	const url = 'https://kalyanimotorsapi.kalyanicrm.com/api/';
	await Promise.all([
		axios.get(url + 'varientbike?name=' + makelinespace(path)),
		axios.get(url + 'iconlocation'),
		axios.get(url + 'blogpage'), //  2
	])
		.then((res) => {
			var locations_h = [];
			var locations_b = [];
			var locations_c = [];
			var locations_r = [];
			for (let i = 0; i < res[1].data.length; i++) {
				const element = res[1].data[i];
				if (element.city === 1) locations_b.push(element);
				else locations_h.push(element);
			}

			var location = localStorage.getItem('location');
			if (location === 'Hydrabad' && locations_h.length > 0)
				location = locations_h[0];
			if (location === 'Channapatna' && locations_c.length > 0)
				location = locations_h[0];
			if (location === 'Ramanagara' && locations_r.length > 0)
				location = locations_h[0];
			if (location === 'Bengaluru' && locations_b.length > 0)
				location = locations_h[0];

			props.setState({
				car: res[0].data[0],
				locations_h,
				locations_b,
				location,
				dynamic_links: res[2].data,
			});
		})
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);
	props.setState({ loading: false });
}
