import axios from 'axios';
import { makespaceline } from '../module/simple';

export async function getServices(props) {
	var paths = window.location.pathname.split('/');
	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/servicebanner')
		.then((res) => {
			var _banners = [];
			for (let i = 0; i < res.data.length; i++) {
				const element = res.data[i];
				_banners.push({ banner_img: element.service_banner });
			}
			var banners = { bengaluru: [], hyderabad: [] };

			for (let i = 0; i < _banners.length; i++) {
				const element = _banners[i];
				if (element.city === 'bengaluru') banners.bengaluru.push(element);
				else banners.hyderabad.push(element);
			}

			props.setState({ banners });
		})
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);
	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/seasons')
		.then((res) => props.setState({ seasonCars: res.data }))
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);

	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/servicevideo')
		.then((res) =>
			props.setState({ youtubeLink: res.data[0].youtube_link ?? '' })
		)
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);

	const sApi =
		'https://kalyanimotorsapi.kalyanicrm.com/api/' +
		(paths[4] === 'seasonservice' ? 'attachseasonservice' : 'service');
	await axios
		.get(sApi)
		.then((res) => {
			var data = paths[4] === 'seasonservice' ? res.data[0].services : res.data;
			var service = data[0];
			for (let i = 0; i < data.length; i++) {
				if (paths[2] === makespaceline(data[i].title)) {
					service = data[i];
					break;
				}
			}
			props.setState({ services: data, service });
		})
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);

	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/iconlocation')
		.then((res) => {
			var locations_h = [];
			var locations_b = [];
			var locations_c = [];
			var locations_r = [];
			for (let i = 0; i < res.data.length; i++) {
				const element = res.data[i];
				if (element.city === 1) locations_b.push(element);
				else locations_h.push(element);
			}

			var location = localStorage.getItem('location');
			if (location === 'Hydrabad' && locations_h.length > 0)
				location = locations_h[0];
			if (location === 'Channapatna' && locations_c.length > 0)
				location = locations_h[0];
			if (location === 'Ramanagara' && locations_r.length > 0)
				location = locations_h[0];
			if (location === 'Bengaluru' && locations_b.length > 0)
				location = locations_h[0];

			props.setState({ locations_h, locations_b, location });
		})
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);
	props.setState({ loading: false });

	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/servicefaq')
		.then((res) => props.setState({ faq: res.data }))
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);
	await axios
		.get('https://kalyanimotorsapi.kalyanicrm.com/api/blogpage')
		.then((res) => props.setState({ dynamic_links: res.data }))
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);

	return 0;
}
