import axios from 'axios';

export async function getdataforContact(props) {
	const url = 'https://kalyanimotorsapi.kalyanicrm.com/api/';

	await Promise.all([
		axios.get(url + 'iconlocation'), // 0
		axios.get(url + 'commonfaq'), // 1
		axios.get(url + 'location'), // 2
		axios.get(url + 'blogpage'), //  3
	])
		.then(function (res) {
			var locations_h = [];
			var locations_b = [];
			var locations_c = [];
			var locations_r = [];

			for (let i = 0; i < res[0].data.length; i++) {
				const element = res[0].data[i];
				if (element.city === 1) locations_b.push(element);
				else locations_h.push(element);
			}

			var location = localStorage.getItem('location');
			if (location === 'Hydrabad' && locations_h.length > 0)
				location = locations_h[0];
			if (location === 'Channapatna' && locations_c.length > 0)
				location = locations_h[0];
			if (location === 'Ramanagara' && locations_r.length > 0)
				location = locations_h[0];
			if (location === 'Bengaluru' && locations_b.length > 0)
				location = locations_h[0];

			////////////////

			// var locs = [];
			// var insetred = false;
			// for (let j = 0; j < res[2].data.length; j++) {
			//   const element = res[2].data[j];
			//   if (element.city === "bangalore") {
			//     insetred = false;
			//     for (let i = 0; i < locs.length; i++) {
			//       const loc = locs[i];
			//       if (loc.title === element.category.title) {
			//         locs[i].data.push(element);
			//         insetred = true;
			//         break;
			//       }
			//     }
			//     if (!insetred)
			//       locs.push({ title: element.category.title, data: [element] });
			//   }
			// }
			// props.setState({ shops_b: locs });
			// locs = [];
			// insetred = false;
			// for (let j = 0; j < res[2].data.length; j++) {
			//   const element = res[2].data[j];
			//   if (element.city === "hyderabad") {
			//     insetred = false;
			//     for (let i = 0; i < locs.length; i++) {
			//       const loc = locs[i];
			//       if (loc.title === element.category.title) {
			//         locs[i].data.push(element);
			//         insetred = true;
			//         break;
			//       }
			//     }
			//     if (!insetred)
			//       locs.push({ title: element.category.title, data: [element] });
			//   }
			// }
			// props.setState({ shops_h: locs });

			var shops = [];

			var areas = [];
			var brands = [];
			var types = [];
			var inserted = false;

			for (let i = 0; i < res[2].data.length; i++) {
				const element = res[2].data[i];
				inserted = false;
				const city = element.city;
				for (let j = 0; j < areas.length; j++) {
					const element1 = areas[j];
					if (city === element1) {
						inserted = true;
						break;
					}
				}
				if (!inserted) areas.push(city);

				inserted = false;
				const category = element.category.title;
				for (let j = 0; j < brands.length; j++) {
					const element1 = brands[j];
					if (category === element1) {
						inserted = true;
						break;
					}
				}
				if (!inserted) brands.push(category);

				inserted = false;
				const type = element.type;
				for (let j = 0; j < types.length; j++) {
					const element1 = types[j];
					if (type === element1) {
						inserted = true;
						break;
					}
				}
				if (!inserted) types.push(type);
			}

			for (let i = 0; i < areas.length; i++) {
				shops.push({ title: areas[i], data: [] });
				for (let j = 0; j < brands.length; j++) {
					shops[i].data.push({ title: brands[j], data: [] });
					for (let k = 0; k < types.length; k++) {
						shops[i].data[j].data.push({ title: types[k], data: [] });
					}
				}
			}

			var area = 0;
			var brand = 0;
			var typ = 0;

			for (let i = 0; i < res[2].data.length; i++) {
				const element = res[2].data[i];

				const city = element.city;
				for (let j = 0; j < areas.length; j++) {
					const element1 = areas[j];
					if (city === element1) {
						area = j;
						break;
					}
				}

				const category = element.category.title;
				for (let j = 0; j < brands.length; j++) {
					const element1 = brands[j];
					if (category === element1) {
						brand = j;
						break;
					}
				}

				const type = element.type;
				for (let j = 0; j < types.length; j++) {
					const element1 = types[j];
					if (type === element1) {
						typ = j;
						break;
					}
				}
				shops[area].data[brand].data[typ].data.push(element);
			}
			console.log(shops);

			////////////////
			props.setState({
				faq: res[1].data,
				locations_h,
				locations_b,
				location,
				shops,
				dynamic_links: res[3].data,
			});
		})
		.catch((e) => [props.setState({ error: 'Not Fount' }), console.log(e)]);

	const path = window.location.pathname.split('/')[3];
	if (path === 'nexa') props.setState({ place: 0 });
	if (path === 'arena') props.setState({ place: 1 });
	if (path === 'commercial') props.setState({ place: 2 });

	props.setState({ loading: false });
}
