import React, { useState } from "react";
import arrowMore from "../asset/ic_expand_more.svg";

function HomeQuestions({ props }) {
  const { faq } = props.state;

  const [tab, settab] = useState(null);

  return (
    <div className="hb_1a" id="hm_f">
      <div className="hb_1d" />
      <div className="hb_1b">
        <div className="hb_1b_a"> Common FAQ </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "6px", width: "100%" }}>
          {faq.map((item, k) => (
            <div
              key={k}
              style={{ cursor: "pointer" }}
              onClick={() => settab(tab === k ? null : k)}
            >
              <div className="dropdown">
                <div className="dropdown_text">{item.title}</div>
                <img
                  className="dropdown_img"
                  src={arrowMore}
                  alt="down arrow"
                />
              </div>
              {k === tab ? (
                <div className="dropdown_data">{item.description}</div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeQuestions;
