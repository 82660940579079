import React, { useState } from 'react';
import { getCarImage } from '../module/api_init';
import { getComaPrice, makespaceline } from '../module/simple';

function HomeCars({ props }) {
  console.log(props);
  const { location } = props.state;
  const items = props.state.seasonCars;
  console.log(items)
  const [page, setpage] = useState(0);
  const selctedTypeStyle = { color: '#00afa0', fontWeight: 'bold' };

  return (
    <div className="hb_1a" id="hm_a">
      <div className="hb_1d" />
      <div className="hb_1b">
        <div className="hb_1b_a"> Maruti Suzuki - New Cars </div>
        <button
          className="hb_1b_b"
          onClick={() => (window.location = '/allcars/' + location.id ?? 0)}
        >
          VIEW ALL CARS
        </button>
      </div>
      <div className="hb_1b1">
        <div className="hb_1b2">
          {items.map((tab, k) => (
            <div key={k} className="hb_1b2a" onClick={() => setpage(k)}>
              <div
                className="hb_1b3"
                style={page === k ? selctedTypeStyle : {}}
              >
                {tab.season_name}
              </div>
              <div
                className="hb_1b3a"
                style={{
                  visibility: page === k ? null : 'hidden',
                }}
              />
            </div>
          ))}
        </div>
        <div className="hb_1b2b" />
        <div className="hb_8a">
          {items[page]?.bikes?.map((item, k) => (
            <div key={k} className="hb_8c">
              <div
                className="hb_8d"
                onClick={() =>
                  (window.location =
                    '/car/' + makespaceline(item.route) + '/' + location.id ??
                    0)
                }
              >
                <img
                  className="hb_8e"
                  src={getCarImage(item.images?.[0]?.images)}
                  alt="img"
                />
              </div>
              <div className="hb_8f">
                <div className="hb_8j">{item.name}</div>
                <div className="hb_8g">
                  ₹ {getComaPrice(item.price ?? '0')}/-
                </div>
                <div
                  className="hb_8h"
                  onClick={() =>
                    props.setState({
                      enquiryPopup: {
                        car: item.name,
                        type: 5,
                      },
                    })
                  }
                >
                  Ex-showroom price
                </div>
                {window.innerWidth < 600 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        fontSize: 9,
                        background: '#047A70',
                        width: '48%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontWeight: '500',
                        borderRadius: 10,
                        height: 20,
                        marginTop: 4,
                      }}
                      onClick={() =>
                        window.location.assign(
                          'tel:' + (location.phone_number ?? '')
                        )
                      }
                    >
                      Call Now
                    </div>
                    <div
                      style={{
                        fontSize: 9,
                        background: '#2E3192',
                        width: '48%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontWeight: '500',
                        borderRadius: 10,
                        height: 20,
                        marginTop: 4,
                      }}
                      onClick={() =>
                        props.setState({
                          enquiryPopup: {
                            car: item.name,
                            type: 0,
                          },
                        })
                      }
                    >
                      Get Best Price
                    </div>
                  </div>
                ) : (
                  <React.StrictMode>
                    <div
                      className="hb_8i"
                      onClick={() =>
                        props.setState({
                          enquiryPopup: {
                            car: item.name,
                            type: 0,
                          },
                        })
                      }
                    >
                      Show On-Road Price
                    </div>
                    <div
                      className="hb_8i_1"
                      onClick={() =>
                        props.setState({
                          enquiryPopup: {
                            car: item.name,
                            type: 6,
                          },
                        })
                      }
                    >
                      Get Best Offers
                    </div>
                  </React.StrictMode>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeCars;
